.background {
  background-color: #fdf7f4;
  min-height: 100vh;
  width: 100vw;

  .navbar {
    border-bottom: 1px dashed rgba(#f7d9c7, 0.7);

    .container {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem 0;

      .logo {
        height: 3rem;
        margin-left: -0.5rem;
      }
    }
  }
}

.home {
  .body {
    .banner {
      display: flex;
      flex-flow: row;
      height: 40rem;
      padding: 5rem 0;

      .content {
        width: 40%;
        margin-right: 2rem;

        .title {
          font-size: 3.6rem;
          font-weight: 700;
        }

        .subTitle {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.8rem;
        }
      }

      .art {
        flex: 1;
        height: 40rem;
        background-color: #eee;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }

      @media only screen and (max-width: 992px) {
        flex-flow: column-reverse;
        padding: 0;
        height: fit-content;

        .content {
          width: 100%;
          margin-right: 0;
        }

        .art {
          flex: auto;
          height: 20rem;
        }
      }
    }

    .sectionHeader {
      .label {
        font-weight: 600;
        font-size: 1.2rem;
      }

      .title {
        width: 50%;
        font-size: 2.3rem;
        font-weight: 700;
        line-height: 3rem;
      }

      .subTitle {
        width: 50%;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.8rem;
      }

      @media only screen and (max-width: 992px) {
        .title {
          width: 100%;
        }

        .subTitle {
          width: 100%;
        }
      }
    }

    .featurePreview {
      display: flex;
      flex-flow: row;
      margin: 0 -1rem;

      .feature {
        height: 10rem;
        margin: 1rem;
        width: auto;
        flex: 0 1 calc(25% - 1rem);
        background-color: #eee;
      }
    }

    .section {
      display: flex;
      flex-flow: row;
      height: 40rem;
      padding: 5rem 0;

      .content {
        flex: 1;
        margin-right: 2rem;

        .title {
          font-size: 1.8rem;
          font-weight: 700;
        }

        .subTitle {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.8rem;
        }
      }

      .art {
        flex: 1;
        height: 40rem;
        background-color: #eee;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }

      &.vertical {
        flex-flow: column;
        height: fit-content;

        .content {
          margin-right: 0;
          margin-bottom: 5rem;
          flex: none;
        }

        .art {
          flex: none;
          width: 100%;
        }
      }

      @media only screen and (max-width: 992px) {
        flex-flow: column;
        padding: 2rem 0;
        height: fit-content;

        .content {
          margin-right: 0;
        }

        .art {
          flex: auto;
          height: 20rem;
        }

        &.vertical {
          flex-flow: column;
          .content {
            margin-bottom: 2rem;
          }
        }
      }
    }

    .showcase {
      display: flex;
      flex-flow: row;
      overflow-x: auto;
      margin: 0 -1rem;
      position: relative;

      .item {
        flex: 0 0 auto;
        height: 25rem;
        width: 30rem;
        margin: 1rem;

        .image {
          height: 20rem;
          width: 100%;
          background-color: #eee;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }

        .content {
          .title {
            font-size: 1rem;
            margin-bottom: 0.5rem;
            font-weight: 700;
          }

          .subTitle {
            font-size: 0.9rem;
            font-weight: 400;
            line-height: 1.5rem;
            margin-top: 0.5rem;
          }
        }
      }

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .stack {
      display: flex;
      flex-flow: column nowrap;
      margin-top: 4rem;

      .item {
        height: 20rem;
        width: 100%;
        background-color: #eee;
        margin-bottom: 2rem;
      }
    }

    .art {
      height: 40rem;
      width: 100%;
      background-color: #eee;
    }
  }
  .footer {
    height: 40rem;
    border-top: 1px dashed rgba(#f7d9c7, 0.7);
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    .content {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      .container {
        display: flex;
        flex-flow: column;
        align-items: center;

        .logo {
          height: 5rem;
        }

        .tagline {
          font-size: 1.9rem;
          font-weight: 600;
          text-align: center;
          margin-top: 0.5rem;
          color: #212121;
        }
      }
    }

    .copyright {
      text-align: center;
      font-size: 0.7rem;
      font-weight: 500;
      margin: 2rem 0;
    }
  }
}
